import { LargeParagrapgh, UppercaseH2 } from "~/components/Text";
import { Icon } from "@iconify/react";
import { Product } from "~/schemas";
import { cn } from "~/lib/utils";

const variants = {
  primary: {
    borderClass: "border-l-primary",
  },
  secondary: {
    borderClass: "border-l-gray-200",
  },
  tertiary: {
    borderClass: "border-l-slate-500",
  },
};

interface ProductDetailProps {
  product: Product;
}

export default function ProductDetail({ product }: ProductDetailProps) {
  return (
    <div
      className={cn(
        "block md:grid grid-cols-2 gap-x-16 border-l-4 pl-8",
        variants[product.featureVariant].borderClass,
      )}
    >
      <div className="">
        <UppercaseH2 className="mb-4">Produkt</UppercaseH2>
        <LargeParagrapgh className="mb-8 md:mb-32">
          {product.title}
        </LargeParagrapgh>
        <ul className="text-xl md:text-2xl list-disc list-outside space-y-8 md:space-y-16 pl-6">
          {product.listItemDescriptions.map((description, index) => (
            <li key={index}>
              <span className="">{description}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col justify-center items-center gap-8 leading-loose mt-8 md:mt-0">
        <Icon className="h-16 w-fit" icon={product.icon} />
        <p>{product.description}</p>
      </div>
    </div>
  );
}
