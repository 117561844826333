import { z } from "zod";

export const ProductSchema = z.object({
  id: z.string(),
  title: z.string(),
  subtitle: z.string(),
  description: z.string(),
  icon: z.string(),
  listItemDescriptions: z.array(z.string()),
  featureVariant: z.enum(["primary", "secondary", "tertiary"]),
});
export type Product = z.infer<typeof ProductSchema>;

export const ContactFormSubmissionSchema = z.object({
  name: z.string().trim().min(1, "Namn får inte vara tomt"),
  organization: z.string().trim().optional(),
  email: z.string().email("E-postadressen är inte giltig"),
  comment: z.string().trim().optional(),
  agreeToPolicy: z.literal("on", {
    message:
      "Du måste godkänna vår integritetspolicy för att skicka formuläret",
  }),
});
export type ContactFormSubmission = z.infer<typeof ContactFormSubmissionSchema>;
