import { AnimatePresence, MotionConfig, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import sampleOffice from "~/assets/images/sample-office.jpg";
import womanLaptop from "~/assets/images/woman-laptop.jpg";
import shakingHands from "~/assets/images/shaking-hands.jpg";
import manPresentation from "~/assets/images/man-presentation.jpg";

const images = [sampleOffice, womanLaptop, shakingHands, manPresentation];

const variants: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exiting: { opacity: 0, scale: 2 },
};

interface ImageGalleryProps {
  className?: string;
}

export default function ImageGallery({ className }: ImageGalleryProps) {
  const [selectedImage, setSelectedImage] = useState(0);

  // Pre-load all images to prevent slight flickering when changing images
  useEffect(() => {
    for (const src of images) {
      const img = new Image();
      img.src = src;
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedImage((prevValue) => {
        if (prevValue === images.length - 1) {
          return 0;
        }
        return prevValue + 1;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={className}>
      <div className="relative w-full h-full overflow-hidden bg-[#202020]">
        <MotionConfig transition={{ duration: 0.7 }}>
          <AnimatePresence initial={false}>
            <motion.img
              className="absolute inset-0 w-full h-full object-cover brightness-50"
              key={images[selectedImage]}
              initial="hidden"
              animate="visible"
              exit="exiting"
              variants={variants}
              src={images[selectedImage]}
              alt="src"
            />
          </AnimatePresence>
        </MotionConfig>
      </div>
    </div>
  );
}
