import { Icon } from "@iconify/react";
import { cn } from "~/lib/utils";
import { Product } from "~/schemas";

const variants = {
  primary: {
    bgClasses:
      "bg-primary group-hover:bg-primary/80 data-[active=true]:bg-primary/80 text-primary-foreground",
    mainIconClasses: "text-primary-foreground",
    arrowIconClasses:
      "group-hover:text-primary-foreground data-[active=true]:text-primary-foreground",
  },
  secondary: {
    bgClasses:
      "bg-gray-200 group-hover:bg-gray-200/80 data-[active=true]:bg-gray-200/80 text-dark",
    mainIconClasses: "text-primary",
    arrowIconClasses:
      "group-hover:text-primary data-[active=true]:text-primary",
  },
  tertiary: {
    bgClasses:
      "bg-slate-500 group-hover:bg-slate-500/80 data-[active=true]:bg-slate-500/80 text-white",
    mainIconClasses: "text-white",
    arrowIconClasses: "group-hover:text-white data-[active=true]:text-white",
  },
};

interface FeatureButtonProps {
  product: Product;
  backgroundImage: string;
  onClick?: () => void;
  active?: boolean;
  className?: string;
}

export default function FeatureButton({
  product,
  backgroundImage,
  onClick,
  active = false,
  className,
}: FeatureButtonProps) {
  return (
    <button className={cn("block group", className)} onClick={onClick}>
      <div className="h-full relative">
        <div
          className={cn(
            "absolute inset-0 flex flex-col justify-end transition duration-300 px-8 xl:px-12 py-11 xl:py-16",
            variants[product.featureVariant].bgClasses,
          )}
          data-active={active}
        >
          <div
            data-active={active}
            className="relative top-12 text-left transition ease-in-out duration-500 group-hover:-translate-y-12 data-[active=true]:-translate-y-12"
          >
            <Icon
              className={cn(
                "relative right-2 h-12 lg:h-16 w-fit",
                variants[product.featureVariant].mainIconClasses,
              )}
              icon={product.icon}
            />
            <p className="text-2xl lg:text-3xl font-medium mt-5 lg:mt-8">
              {product.title}
            </p>
            <p className="mt-2">{product.subtitle}</p>
            <Icon
              icon="material-symbols-light:arrow-right-alt"
              className={cn(
                "relative right-2 h-12 w-fit text-transparent transition duration-500",
                variants[product.featureVariant].arrowIconClasses,
              )}
              data-active={active}
            />
          </div>
        </div>
        <div
          className="h-full bg-cover"
          style={{ backgroundImage: `url("${backgroundImage}")` }}
        ></div>
      </div>
    </button>
  );
}
