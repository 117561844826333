import { ClassName } from "~/utils";

export default function ArrowDown({ className }: ClassName) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="41"
      fill="none"
      viewBox="0 0 31 41"
    >
      <g stroke="currentColor" clipPath="url(#clip0_1_2)">
        <path d="M15.5 0v39.5M1 25.5L15.5 40 30 25.5"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <path fill="currentColor" d="M0 0H31V41H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
