import { ButtonHTMLAttributes } from "react";
import { cn } from "~/lib/utils";
import { Slot } from "@radix-ui/react-slot";

interface AnimatedUnderlineButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  asChild?: boolean;
}

export default function AnimatedUnderlineButton({
  active = false,
  asChild = false,
  className,
  children,
  ...rest
}: AnimatedUnderlineButtonProps) {
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      className={cn(
        "font-normal group hover:text-primary transition duration-300",
        active && "text-primary",
        className,
      )}
      {...rest}
    >
      <div>
        {children}
        <div className={cn("relative block h-[2px] w-full")}>
          <div
            className={cn(
              "absolute inset-0 bg-primary scale-x-0 group-hover:scale-x-100 group-focus-within:scale-x-100 origin-left transition duration-500",
              active && "scale-x-100",
            )}
          />
        </div>
      </div>
    </Comp>
  );
}
