import { createPortal } from "react-dom";
import { RemoveScroll } from "react-remove-scroll";
import Logo from "~/components/Logo";
import { Icon } from "@iconify/react";
import Content from "~/components/Content";

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function Menu({ isOpen, onClose }: MenuProps) {
  if (!isOpen) return null;

  return createPortal(
    <RemoveScroll>
      <div className="fixed inset-0 z-50 bg-dark/90 backdrop-blur text-white">
        <Content className="flex justify-between py-8">
          <Logo className="h-8 w-fit" />
          <button
            className="flex items-center gap-2 text-lg font-normal"
            onClick={onClose}
          >
            <span>Stäng</span>
            <Icon className="h-8 w-fit" icon="material-symbols-light:close" />
          </button>
        </Content>
        <div className="flex flex-col items-center justify-center gap-8 mt-16">
          <SectionLink label="Home" />
          <SectionLink label="About" />
          <SectionLink label="Services" />
          <SectionLink label="Contact" />
        </div>
      </div>
    </RemoveScroll>,
    document.body,
  );
}

interface SectionLinkProps {
  label: string;
}

function SectionLink({ label }: SectionLinkProps) {
  return (
    <div className="flex items-center gap-8 text-3xl">
      <span>{label}</span>
      <Icon
        className="h-12 w-fit"
        icon="material-symbols-light:arrow-right-alt"
      />
    </div>
  );
}
