import Logo from "~/components/Logo";
import AnimatedUnderlineButton from "~/components/AnimatedUnderlineButton";
import { Button } from "~/components/ui/button";
import Content from "~/components/Content";
import { Icon } from "@iconify/react";
import Menu from "~/components/Menu";
import { useState } from "react";
import { scrollToElement } from "~/utils.client";
import ThemeToggleButton from "~/components/ThemeToggleButton";
import { Link } from "@remix-run/react";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="py-4 md:py-6 lg:py-12 bg-background">
      <Content>
        <div className="grid grid-cols-[min-content_1fr] xl:grid-cols-[2fr_1fr]">
          <div className="flex items-center gap-16">
            <Link to="/">
              <Logo className="h-6 md:h-8 w-auto" />
            </Link>
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
            <nav className="hidden xl:block">
              <ul className="flex gap-8">
                <li>
                  <AnimatedUnderlineButton
                    onClick={() => scrollToElement("ourProducts")}
                  >
                    Våra produkter
                  </AnimatedUnderlineButton>
                </li>
                <li>
                  <AnimatedUnderlineButton
                    onClick={() => scrollToElement("contact")}
                  >
                    Kontakt
                  </AnimatedUnderlineButton>
                </li>
              </ul>
            </nav>
          </div>
          <div className="flex justify-end items-center gap-8">
            <Button
              className="xl:hidden"
              variant="ghost"
              size="icon"
              onClick={() => setIsMenuOpen(true)}
            >
              <Icon className="h-8 w-fit" icon="material-symbols-light:menu" />
            </Button>
            <p className="hidden md:block font-light">Vill du veta mer?</p>
            <Button
              className="hidden md:block"
              onClick={() => scrollToElement("contact")}
            >
              Kontakta oss
            </Button>
            <ThemeToggleButton />
          </div>
        </div>
      </Content>
    </header>
  );
}
