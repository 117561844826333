import { cn } from "~/lib/utils";
import { TextareaHTMLAttributes } from "react";

interface AnimatedUnderlineTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

export default function AnimatedUnderlineTextarea(
  props: AnimatedUnderlineTextareaProps,
) {
  return (
    <span className="group">
      <textarea
        className={cn(
          "w-full resize-y outline-none bg-transparent focus:placeholder:text-muted-foreground py-2",
        )}
        rows={3}
        {...props}
      />
      <div className={cn("relative block h-[1px] w-full bg-slate-300")}>
        <div className="absolute inset-0 bg-primary scale-x-0 group-hover:scale-x-100 group-focus-within:scale-x-100 origin-left transition duration-500" />
      </div>
    </span>
  );
}
