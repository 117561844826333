import { useField } from "remix-validated-form";
import { cn } from "~/lib/utils";

interface InputErrorProps {
  name: string;
  className?: string;
}

export default function InputError({ name, className }: InputErrorProps) {
  const field = useField(name);

  if (!field.error) return null;

  return <p className={cn("text-red-500 text-sm", className)}>{field.error}</p>;
}
