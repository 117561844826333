import { cn } from "~/lib/utils";
import { InputHTMLAttributes } from "react";
import { useField } from "remix-validated-form";

interface AnimatedUnderlineInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export default function AnimatedUnderlineInput(
  props: AnimatedUnderlineInputProps,
) {
  const field = useField(props.name);

  return (
    <span className="group">
      <input
        className={cn(
          "outline-none bg-transparent focus:placeholder:text-muted-foreground py-2",
        )}
        {...props}
      />
      <div className={cn("relative block h-[1px] w-full bg-slate-300")}>
        <div
          className={cn(
            "absolute inset-0 bg-primary origin-left transition duration-500",
            field.error ? "bg-red-500" : "bg-primary",
            field.error
              ? "scale-x-100"
              : "scale-x-0 group-hover:scale-x-100 group-focus-within:scale-x-100",
          )}
        />
      </div>
      {field.error && <p className="text-red-500 text-sm">{field.error}</p>}
    </span>
  );
}
